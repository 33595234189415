// breakpoint
@import "breakpoint.scss";

// fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
// colors
:root {
  // fonts
  //--font-family-main: "Poppins", sans-serif;
  --font-family-main: "Montserrat", sans-serif;
  --font-family-logo: "Playfair Display SC", serif;

  --font-size-s: 14px;
  --font-line-s: 20px;
  --font-main-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  --font-size-m: 18px;
  --font-line-m: 26px;
  --font-main-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

  --font-size-l: 24px;
  --font-line-l: 32px;
  --font-main-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  // colors
  --basic-bg-color: white;

  //--primary-color: lightslategrey;
  --primary-color: rgb(39, 39, 39);
  --primary-hover-color: rgb(83, 83, 83);
  //--secondary-color: #ffe4c4;
  --secondary-color: white;

  --supporting-color: grey;

  --font-color: #363e46;

  --opacity-color: #000000cb;

  --fullScreen-color: #000000d8;


  --selected-color: rgb(238, 238, 238);

  --error-color: rgb(148, 3, 15);
  --error-bg-color: rgb(253, 232, 234);


  // heights

  --header-height: 42px;
  --footer-height: 303px;
  --container-height: calc(100vh - 42px - 303px);
  --select-height: 43px;

  @include breakpoint(md) {
    --footer-heigth: 253px;
    --container-height: calc(100vh - 42px - 253px);
  }
  @include breakpoint(lg) {
    --header-height: 62px;
    --footer-height: 253px;
    --container-height: calc(100vh - 62px - 253px);
  }

  // paddings
  --padding-s: 0.5rem;
  --padding-m: 1rem;
  --padding-l: 1.5rem;

  // margins
  --margin-s: 0.5rem;
  --margin-m: 1rem;
  --margin-l: 1.5rem;

  // z-index
  --header-z: 10;
  --select-z: 5;
  --mobilSearch-z: 15;
  --mobileMenu-z: 20;
  --searchOverlay-z: 50;
}

$font: rgb(54, 62, 70);
