.productItem {
  margin-bottom: 15px;
  .wrapper {
    position: relative;
    .productImg {
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
        transition: transform 0.5s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .like {
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .prodictLike {
        width: 22px;
        height: 22px;
        fill: transparent;
        stroke: var(--secondary-color);
        :hover {
          //transform: scale(1.5);
          cursor: pointer;
        }
      }
      .liked {
        fill: var(--secondary-color);
      }
    }
  }

  .productContent {
    .productName {
      text-align: center;
      font: var(--font-main-s);
      margin: 5px 0;
    }
    .productPrice {
      text-align: center;
      font: var(--font-main-s);
    }
  }
}
