.mobileMenu {
  background-color: white;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  padding: 5px 8px;
  top: 0;
  left: -100vw;
  transition: 0.2s ease-in-out;
  z-index: var(--mobileMenu-z);

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .close,
    .search {
      width: 34px;
      height: 34px;
      fill: var(--primary-color);
    }
  }
  hr {
    border: none;
    height: 1px;
    color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .content {
    /* display: flex;
      flex-direction: column;
      align-items: center; */
    .item {
      width: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        font: var(--font-main-m);
        padding: 14px 0;
        color: var(--primary-color);
      }
      .dropArrow {
        margin-left: 1rem;
        width: 18px;
        height: 18px;
        fill: var(--primary-color);
        transform: rotate(180deg);
      }
      .active {
        transform: rotate(0deg);
      }
      .dropDown {
        padding-left: 1rem;
        .ddItem {
          display: block;
          text-decoration: none;
          font: var(--font-main-m);
          padding: 14px 0;
          color: var(--primary-color);
        }
      }
    }
  }
}

.activeMenu {
  left: 0;
}
