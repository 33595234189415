@import "app/style/variables/breakpoint.scss";

.footer {
  color: var(--primary-color);
  margin-top: calc(var(--margin-m) * 2);
  padding: 0 var(--padding-m);
  .wrapper {
    @include breakpoint(md) {
      margin: auto;
      max-width: 600px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(10*var(--padding-l));
    }

    hr {
      margin-top: var(--padding-m);
      border: none;
      height: 1px;
      color: var(--primary-color);
      background-color: var(--primary-color);
    }
    .menu {
      .items {
        display: flex;
        flex-direction: column;
        margin-bottom: calc(2 * var(--margin-m));
        .subMenu {
          display: flex;
          flex-direction: column;
          padding-bottom: var(--padding-s);
          .item {
            padding: 0.25rem 0;
            text-decoration: none;
            color: var(--primary-color);
            font: var(--font-main-s);
          }
        }
      }
    }
    .section {
      .links {
        display: flex;
        justify-content: space-evenly;
        @include breakpoint(md) {
          flex-direction: column;
        }
        .linkIcon {
          padding: 5px;
          height: 45px;
          width: 45px;
          fill: var(--primary-color);
        }
        .link {
          text-decoration: none;
          color: var(--primary-color);
          font: var(--font-main-s);
          @include breakpoint(md) {
            padding: 0.25rem 0;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    padding: 10px;
    font-size: 10px;
  }
}

.product {
  margin-bottom: 66px;
}
