@import "reset.scss";
@import "variables/global.scss";
@import "variables/breakpoint.scss";

html {
  scrollbar-width: none;
}

body {
  overflow-y: scroll;
}

.app {
  font-family: var(--font-family-main);
  //height: 100vh;
  //width: 100vw;
  //overflow-y: overlay;
}

.text {
  color: var(--primary-color);
  margin: 1rem 0;
}

.fs22 {
  font-size: 22px;
}

.fs20 {
  font-size: 20px;
}

main {
  .container {
    width: 100%;
    margin-top: var(--header-height);
    position: relative;
    min-height: var(--container-height);
    @include breakpoint(md) {
      //max-width: 800px;
    }
    @include breakpoint(lg) {
      //max-width: 1100px;
    }
    @include breakpoint(xl) {
      //max-width: 1400px;
    }
  }
}

.product__sort {
  margin-top: 1rem;
}
