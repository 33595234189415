.search {
  .blocker {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    //height: var(--header-height);
    //background-color: white;
    z-index: 110;
  }
  .overlay {
    position: fixed;
    top: var(--header-height);
    right: 0;
    left: 0;
    height: 100vh;
    background-color: var(--opacity-color);

    z-index: 100;
  }
}
