@import "app/style/variables/breakpoint.scss";

.searchInput {
  position: relative;
  display: flex;
  justify-content: end;
  z-index: 150;
  cursor: pointer;
  .search {
    padding: 3px;

    width: 32px;
    height: 30px;
    margin-right: -2px;
    background: none;
    border: 1px solid transparent;
    //border-radius: 50px;
    box-sizing: border-box;
    color: var(--secondary-color);
    outline: none;
    transition: 0.5s;
    cursor: default;
    pointer-events: none;
  }
  .searchIcon {
    height: 26px;
    width: 26px;
    fill: var(--secondary-color);

    position: absolute;
    top: calc(50% - 12px);
    color: var(--secondary-color);
    transition: 0.2s;
  }
}
.searching {
  .search {
    border: 1px solid var(--secondary-color);
    width: 100%;
    background: #3b3640;
    border-radius: 5px;
    display: block;
    padding: 0 2.5rem 0 0.5rem;
    cursor: pointer;
    
  }
  .searchIcon {
    display: block;
    
  }
}
