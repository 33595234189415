@import "app/style/variables/breakpoint.scss";

.show {
  transform: translateY(0);
}
.hide {
  transform: translateY(-100%);
  @include breakpoint(lg) {
    transform: translateY(0);
  }
}

.header {
  background: var(--primary-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in;
  z-index: var(--header-z);

  a {
    text-decoration: none;
    color: var(--secondary-color);
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calc(var(--padding-s) / 2) var(--padding-s);
    @include breakpoint(lg) {
      padding: var(--padding-m) var(--padding-l);
    }
  }

  .burger {
    flex-basis: 20%;
    display: flex;

    .burgerIcon {
      width: 34px;
      height: 34px;
      fill: var(--secondary-color);
    }
  }

  .naviBar {
    flex-basis: 20%;
    display: flex;
    @include breakpoint(lg) {
      flex-basis: 30%;
    }
    .naviLink {
      font: var(--font-main-s);
      cursor: pointer;
      margin-right: var(--margin-l);
    }
  }

  .logo {
    flex-basis: 60%;
    font-size: 18px;
    text-align: center;
    font-family: var(--font-family-logo);
    letter-spacing: 0.5px;
    @include breakpoint(lg) {
      flex-basis: 40%;
      font-size: 22px;
      letter-spacing: 1px;
    }
  }

  .search {
    position: absolute;
    right: 192px;
  }

  .icons {
    flex-basis: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @include breakpoint(md) {
      justify-content: end;
    }
    @include breakpoint(lg) {
      flex-basis: 30%;
    }
  }

  .link {
    height: 22px;
    width: 22px;
    fill: var(--secondary-color);
    @include breakpoint(md) {
      margin-left: var(--margin-l);
    }
    @include breakpoint(lg) {
      height: 24px;
      width: 24px;
      margin-left: calc(2 * var(--margin-m));
    }
  }

  .icon {
    height: 100%;
    width: 100%;
    fill: var(--secondary-color);
  }
  .like {
    fill: transparent;
    stroke: var(--secondary-color);
  }

  .basketIcon {
    position: relative;
  }
  .basketCount {
    position: absolute;
    background-color: var(--secondary-color);
    color: black;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -4px;
    right: -8px;
    font-size: 12px;
    font-weight: 700;
  }
}

.main {
  //background-color: var(--opacity-color);
  background: linear-gradient(var(--opacity-color), 80%, #00000000);
  transform: translateY(0);
}

.main.hide {
  background-color: black;
  transform: translateY(0);
}

.white {
  background-color: #fff;

}

.white {
  background: #fff;

  a {
    text-decoration: none;
    color: var(--primary-color);
  }
  .naviBar {
    .naviLink {
      color: var(--primary-color);
    }
  }

  .logo {
    color: var(--primary-color);
  }

  .link {
    fill: var(--primary-color);
  }

  .icon {
    fill: var(--primary-color);
  }
  .like {
    fill: transparent;
    stroke: var(--primary-color);
  }

  .basketCount {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
}
